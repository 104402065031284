<template>
    <tbody>
        <tr
            v-for="item in items"
            :key="item._id"
            :class="`${isStatusClosed(item) ? 'disabled' : ''}`"
        >
            <!-- === === DEPARTMENT SHORT NAME === === -->
            <td class="truncate">
                {{ item.shortName || $t('departments:empty') }}
            </td>

            <!-- === ===  DEPARTMENT FULL NAME === === -->
            <td class="truncate">{{ item.name || $t('departments:empty') }}</td>

            <!-- === ===  DEPARTMENT LEADER=== === -->
            <td class="truncate">
                {{
                    item.leader
                        ? `${item.leader.name} ${item.leader.lastname}`
                        : $t('departments:empty')
                }}
            </td>

            <!-- === === STATUS === === -->
            <td>
                <v-chip
                    :color="$get(item, 'status.color.background', '#555555')"
                    :style="`color: ${$get(
                        item,
                        'status.color.font',
                        '#FFFFFF'
                    )}`"
                    dark
                    label
                    small
                    >{{
                        $get(item, 'status.name', $t('departments:empty'))
                    }}</v-chip
                >
            </td>

            <!-- === === AKCJA === === -->
            <td style="text-align: right;">
                <v-icon small v-if="hasFile(item.folders)"
                    >mdi-paperclip</v-icon
                >
                <v-menu bottom v-if="remove()">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                            <v-icon color="iconGray" small
                                >mdi-dots-vertical</v-icon
                            >
                        </v-btn>
                    </template>
                    <v-list dense>
                        <!-- === === REMOVE === === -->
                        <v-list-item @click="openConfirmModal(item._id)">
                            <v-list-item-icon class="mr-0">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Usuń</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn
                    icon
                    small
                    @click="$router.push({ path: `/departments/${item._id}` })"
                >
                    <v-icon color="secondary" small
                        >mdi-arrow-right-thick</v-icon
                    >
                </v-btn>
            </td>
        </tr>
        <ConfirmModal
            :openConfirm="openConfirm"
            @close-confirm="closeConfirmModal"
        >
            <div slot="main">
                <ConfirmContent />
            </div>
            <div slot="buttons">
                <div class="d-flex justify-center">
                    <v-btn
                        color="rgb(117, 117, 117)"
                        width="110"
                        height="40"
                        @click="closeConfirmModal()"
                        class="white--text mx-2"
                        >{{ $t('global:cancel') }}</v-btn
                    >
                    <v-btn
                        width="110"
                        height="40"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="removeDepartment()"
                        >{{ $t('global:remove') }}</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </tbody>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmContent from '../ConfirmModal/ConfirmContent'
export default {
    i18nOptions: { namespaces: ['departments'] },
    props: ['items'],
    components: {
        ConfirmContent,
    },
    data: () => ({
        openConfirm: false,
        removeItemID: '',
    }),
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    methods: {
        ...mapActions(['deleteDepartment']),
        openConfirmModal(id) {
            this.removeItemID = id
            this.openConfirm = true
        },
        closeConfirmModal() {
            this.openConfirm = false
        },
        async removeDepartment() {
            let result = await this.deleteDepartment({ id: this.removeItemID })
            if (result) this.openConfirm = false
        },
        remove() {
            return this.checkPermissions('DELETE')
        },
    },
}
</script>

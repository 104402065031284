<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" class="py-0">
                <RegistryHeader
                    :title="getDepartmentsRegistry.name"
                    :avatarData="getDepartmentsRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                 :title="getDepartmentsRegistry.name"
                    :headers="headers"
                    :length="getDepartmentTable.pages"
                    :page="getDepartmentTable.page"
                    :defaultFields="getDepartmentsSearchFields.searchFields"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :loading="getDepartmentTable.loading"
                >
                    <Columns slot="columns" :items="getDepartmentTable.items" />
                </Table>
                <Modal
                    :title="$t('global:addition')"
                    :open="open"
                    @close="closeModal"
                >
                    <AEContent slot="AEContent" />
                    <Buttons
                        slot="buttons"
                        :action="'create'"
                        @closeModal="open = false"
                    />
                </Modal>
                <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                v-if="add()"
                                small
                                @click="openModal"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('departments:addDepartment') }}</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import store from './../../../store/index'
import Columns from './../../../components/Registries/Departments/Table/Columns'
import AEContent from './../../../components/Registries/Departments/Modal/Content'
import Buttons from './../../../components/Registries/Departments/Modal/Buttons'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['departments', 'global'] },
    components: {
        Columns,
        Buttons,
        AEContent,
    },
    data() {
        return {
            open: false,

            headers: [
                {
                    text: this.$t('departments:shortName'),
                    value: 'shortName',
                    width: '12%',
                    showSearch: true,
                },
                {
                    text: this.$t('departments:fullName'),
                    value: 'name',
                    width: '40%',
                    showSearch: true,
                },
                {
                    text: this.$t('departments:leader'),
                    value: 'leader.name join leader.lastname',
                    width: '26%',
                    showSearch: true,
                },
                {
                    text: this.$t('departments:status'),
                    value: 'status.name',
                    width: '100px',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '150px',
                    sortable: false,
                },
            ],
        }
    },
    computed: {
        ...mapGetters([
            'getDepartmentTable',
            'getDepartmentsRegistry',
            'getDepartmentsSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions([
            'fetchAllDepartments',
            'fetchDepartmentsUsers',
            'fetchDepartmentsStatuses',
            'fetchDepartmentsTable',
        ]),
        ...mapMutations([
            'clearDepartmentModal',
            'setDepartmentsTable',
            'clearDepartmentsErrors',
            'clearDepartmentsTable',
            'setDepartmentsSearch',
        ]),
        async openModal() {
            await Promise.all([
                this.fetchDepartmentsStatuses(),
                this.fetchAllDepartments(),
                this.fetchDepartmentsUsers(),
            ])
            this.open = true
        },
        closeModal() {
            this.clearDepartmentModal()
            this.clearDepartmentsErrors()
            this.open = false
        },
        add() {
            return this.checkPermissions('CREATE')
        },
        setSearch(search) {
            this.setDepartmentsTable({ search, page: 1 })
            this.fetchDepartmentsTable()
        },
        setPage(page) {
            this.setDepartmentsTable({ page })
            this.fetchDepartmentsTable()
        },
        setSort(sort) {
            this.setDepartmentsTable({ sort })
            this.fetchDepartmentsTable()
        },
        setSearchFields(searchFields) {
            this.setDepartmentsSearch(searchFields)
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearDepartmentsTable()
            this.fetchDepartmentsTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/departments/:id')
            store.commit('clearDepartmentsTable')
        store.dispatch('fetchDepartmentsTable').then(() => next())
    },
}
</script>
